import { Tools } from './tools'

export const Limiting = {
  get: (wrapper) => JSON.parse(wrapper.dataset.limiting || null) || {},
  set: (wrapper, limiting) => {
    wrapper.dataset.limiting = limiting ? JSON.stringify(limiting) : ''
  },
  update: (wrapper) => {
    const limiting = Limiting.get(wrapper)

    if (!Object.keys(limiting).length) {
      limiting.initial = parseInt(wrapper.dataset.initial)
      limiting.perPage = parseInt(wrapper.dataset.perPage)
      limiting.page = 1
    }
    limiting.page = parseInt(limiting.page) + 1
    Limiting.set(wrapper, limiting)
    return limiting
  },
  reset: (wrapper) => {
    const limiting = Limiting.get(wrapper)
    limiting.page = 1
    Limiting.set(wrapper, limiting)
    return limiting
  },
  Manual: {
    getHiddenItems: (wrapper) => {
      const listing = Tools.Listing(wrapper.dataset.elementId)
      return listing.querySelectorAll('[data-hidden="true"]')
    },
    showHiddenItems: (wrapper) => {
      Array.from(Limiting.Manual.getHiddenItems(wrapper))
        .slice(0, Limiting.get(wrapper).perPage)
        .forEach((item) => {
          item.dataset.hidden = 'false'
        })
    },
  },
}
