;(() => {
  document.addEventListener('DOMContentLoaded', async () => {
    // REVIEW US STATES BLOCK
    const statesWrapper = document.querySelector('[data-states]')
    if (statesWrapper) {
      const states = JSON.parse(statesWrapper.dataset.states)

      const svg = statesWrapper.querySelector('svg')
      if (svg) {
        svg.querySelectorAll('g[data-state]').forEach((g) => {
          if (states.includes(g.dataset.state.toLowerCase())) {
            g.classList.add('supported')
          } else {
            g.classList.add('unsupported')
          }
        })
      }
    }
  })
})()
