export const kemokuFetch = async (body, type = 'json') => {
  const response = await window.fetch(Kemoku.ajaxUrl, {
    // eslint-disable-line
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' },
    body,
  })
  const res = type === 'text' ? await response.text() : await response.json()
  return res
}
